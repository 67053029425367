import React, { useEffect, useState } from "react";
import Image from "next/image";
import { bannerLogoUrlFromName } from "@/lib/media";
import { InterstitialData } from "@/types/api/adverts";
import styles from "@/components/Interstitial/Interstitial.module.scss";
import { getSiteIds } from "@/lib/http/offers";
import { gaEvent } from "@/lib/gtag";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { getLocalisedConfig } from "@/lib/geo";
import { localisedConfig } from "@/components/Interstitial/localisedConfig";

interface InterstitialProps {
  data: InterstitialData;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  region?: string | null;
}

export const Interstitial = ({
  data,
  setIsOpen,
  region,
}: InterstitialProps) => {
  const [locale] = useLocaleFromRouter();
  const [localisedConfigData, setLocalisedConfigData] = useState(
    getLocalisedConfig({
      locale: region ?? locale,
      fallbackKey: "default",
      localisedConfig,
    })
  );

  useEffect(() => {
    setLocalisedConfigData(
      getLocalisedConfig({
        locale: region ?? locale,
        fallbackKey: "default",
        localisedConfig,
      })
    );
  }, [region, locale]);

  const { meta } = data;

  const featuredAndEnabledOnLocale =
    meta.source.featured && localisedConfigData["featuredOfferUI"];

  const interstitialClassName = `${styles.interstitialContainer} ${
    featuredAndEnabledOnLocale ? styles.featuredOfferContainer : ""
  }`;

  const bookmakerData = meta.source.bookmaker[0];

  const ctaButtonColorStyling = `rgba(${bookmakerData.branding.primary.join(
    ","
  )})`;

  const ctaButtonTextStyling = `rgba(${bookmakerData.branding.text.join(",")})`;

  const gaOfferPressedEvent = async (): Promise<void> => {
    const siteIdLocation = "advert_codes";
    const { data: siteIdsData } = await getSiteIds(siteIdLocation);
    const { title, bookmaker } = data.meta.source;
    const { name, name_cleansed } = bookmaker[0];

    const siteId = siteIdsData?.[siteIdLocation]?.[name_cleansed] || null;

    const gaEventParams = {
      bookmaker: name,
      category: "Interstitial",
      offer: title,
      site_id: siteId ?? "",
    };

    gaEvent("offer_pressed", gaEventParams);
    setIsOpen(false);
  };

  return (
    <div
      data-testid="interstitial"
      className={interstitialClassName}
      data-featured={featuredAndEnabledOnLocale}
    >
      {featuredAndEnabledOnLocale ? (
        <span>{localisedConfigData.text.featured}</span>
      ) : null}

      <div className={styles.interstitialContent}>
        <div className={styles.bookmakerLogoAndTitleContainer}>
          <Image
            src={bannerLogoUrlFromName(bookmakerData.name_cleansed)}
            alt={`${bookmakerData.name} logo`}
            width={40}
            height={40}
          />

          <h2>{meta.source.title}</h2>
        </div>

        <p>{meta.source.description}</p>

        <div className={styles.ctaButtonContainer}>
          <a
            style={{
              backgroundColor: ctaButtonColorStyling,
              color: ctaButtonTextStyling,
            }}
            href={meta.source.link}
            target={meta.source.target || "_blank"}
            onClick={gaOfferPressedEvent}
          >
            {meta.source.cta ?? localisedConfigData.text.cta}
          </a>
        </div>
      </div>
    </div>
  );
};
