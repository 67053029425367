import React, { useEffect, useRef, useState } from "react";
import styles from "@/components/Modal/Modal.module.scss";
import { CloseIcon } from "@/components/icons/CloseIcon";
import { useDarkMode } from "@/hooks/useDarkMode";
import { getActiveBrand, getFreeBetPath } from "@/lib/brand";
import { FP_BRAND } from "@/constants/brands";
import { useDetectOutsideClick } from "@/hooks/DetectOutsideClick";
import { Interstitial } from "@/components/Interstitial/Interstitial";
import { InterstitialData } from "@/types/api/adverts";
import { getCookieValueByName } from "@/lib/cookies";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { getLocalisedConfig } from "@/lib/geo";
import { localisedConfig } from "@/components/Modal/localisedConfig";
import { SportBrandShortNames } from "@/types/brand";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";
import { FooterLegislation } from "../FooterLegislation/FooterLegislation";

interface ModalProps {
  interstitials?: InterstitialData[];
  region?: string | null;
}

export const Modal = ({ interstitials, region }: ModalProps) => {
  const brand = getActiveBrand();
  const [locale] = useLocaleFromRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const defaultConfigKey = brand === FP_BRAND ? FP_BRAND : "default";
  const [localisedConfigData, setLocalisedConfigData] = useState(
    getLocalisedConfig({
      locale: region ? region : locale,
      fallbackKey: defaultConfigKey,
      localisedConfig,
    })
  );
  const hasInterstitials =
    interstitials && Object.keys(interstitials).length !== 0;

  // For each incoming interstitial, add to array to compare with cookie array
  const activeInterstitialIds: string[] = [];
  if (hasInterstitials) {
    interstitials.forEach((data) => {
      activeInterstitialIds.push(data._id);
    });
  }

  const stringifiedIds = JSON.stringify(activeInterstitialIds);
  const activeStringifiedCookieValues =
    getCookieValueByName("multi_interstitial") ?? "[]";

  const [seenCookieValues] = useState<string[]>(
    JSON.parse(activeStringifiedCookieValues)
  );

  const allActiveInterstitialsSeen = activeInterstitialIds.every(
    (activeInterstitial) => seenCookieValues.includes(activeInterstitial)
  );

  const [colourMode] = useDarkMode();

  useEffect(() => {
    // If all interstitial IDs are within the active cookie, don't render
    if (!allActiveInterstitialsSeen) {
      // Delay modal opening for SEO reasons
      const timer = setTimeout(() => {
        // Set the cookie
        document.cookie = `multi_interstitial=${stringifiedIds}; Max-Age=31536000; Secure`;

        setLocalisedConfigData(
          getLocalisedConfig({
            locale: region ? region : locale,
            fallbackKey: defaultConfigKey,
            localisedConfig,
          })
        );

        setIsOpen(true);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [
    allActiveInterstitialsSeen,
    defaultConfigKey,
    stringifiedIds,
    locale,
    region,
  ]);

  // Close modal when clicking outside it
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const modalContainerRef = useDetectOutsideClick(
    () => setIsOpen(!isOpen),
    closeModalRef
  );

  // Filter already seen interstitials
  const interstitialsToRender =
    hasInterstitials &&
    interstitials.filter((data) => {
      return !seenCookieValues.includes(data._id);
    });

  const modalSizes = new Map([
    [3, "1000px"],
    [2, "750px"],
    [1, "500px"],
  ]);

  return (
    <>
      {isOpen && interstitialsToRender ? (
        <div className={styles.modalOverlay}>
          <div
            ref={modalContainerRef as React.RefObject<HTMLDivElement>}
            className={styles.modalContainer}
            style={{
              maxWidth: modalSizes.get(interstitialsToRender.length),
            }}
          >
            <div className={styles.modalHeader}>
              <h2>{localisedConfigData["header"]}</h2>
              <button onClick={() => setIsOpen(!isOpen)}>
                <CloseIcon />
              </button>
            </div>

            <div className={styles.childrenContainer}>
              {interstitialsToRender.map((data) => {
                return (
                  <Interstitial
                    key={data._id}
                    data={data}
                    setIsOpen={setIsOpen}
                    region={region}
                  />
                );
              })}
            </div>

            <LinkNoPrefetch
              className={styles.freeBetsLink}
              href={getFreeBetPath(brand, region)}
            >
              {localisedConfigData["button"]}
            </LinkNoPrefetch>
            <div className={styles.gambleSafeContainer}>
              <FooterLegislation
                brand={brand as SportBrandShortNames}
                region={region}
                colourMode={colourMode}
                parent="modal"
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
