import { OfferResponse, SiteIdsResponse } from "@/types/api/offer";
import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";

export const getOffersById = async (
  idArr: string[],
  locale: string
): Promise<OfferResponse> => {
  const ids = idArr.join();
  const path = "offers/ids";
  const queryParams = { ids, locale };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};

export const getSiteIds = async (codes?: string): Promise<SiteIdsResponse> => {
  const path = "site-ids";
  const queryParams = { codes };

  return await getDataFromPathAndQueryParams({ path, queryParams });
};
