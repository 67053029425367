import { BRAZIL_LOCALE } from "@/constants/countries";

export const defaultText = {
  featured: "Featured offer",
  cta: "Claim now",
};

export const localisedConfig = {
  "ca-on": {
    featuredOfferUI: false,
    text: {
      featured: "",
      cta: "Claim now",
    },
  },
  [BRAZIL_LOCALE]: {
    featuredOfferUI: true,
    text: {
      featured: "Oferta em destaque",
      cta: "Reivindicar agora",
    },
  },
  default: {
    featuredOfferUI: true,
    text: defaultText,
  },
};
