import { ReactElement } from "react";
import Head from "next/head";
import OfferSidebar from "@/components/OfferSidebar/OfferSidebar";
import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { getActiveBrand, isSidebarDisabled } from "@/lib/brand";
import { ContentLayoutProps } from "@/types/page";
import styles from "@/components/layout/Layout.module.scss";
import { Tramline } from "@/components/Tramline/Tramline";
import { Mpu } from "@/components/Mpu/Mpu";
import { buildSchemaContent } from "@/lib/string";
import { BRAND_DETAILS_MAP, FP_BRAND } from "@/constants/brands";
import { WidgetMap } from "@/components/WidgetMap/WidgetMap";
import { ContentWidgetsArray } from "@/types/api/content";
import { mediaUrlFromSlug } from "@/lib/media";
import { StayConnected } from "@/components/StayConnected/StayConnected";
import { getSiteUrl } from "@/lib/sitemap";
import { getHtmlLangCode, getLocalisedConfig } from "@/lib/geo";
import { localisedConfig } from "@/components/layout/localisedConfig";
import { NO_INDEX_PAGE_REGEX_PATTERNS } from "@/constants/pages/meta";
import { getPageCanonicalLinks } from "@/lib/page";

function ContentLayout({
  children,
  page,
  advertsData,
  breadcrumbsToEdit,
  isMobileDevice,
  region,
}: ContentLayoutProps): ReactElement {
  const brand = getActiveBrand();
  const siteUrl = getSiteUrl();
  // The sidebar field is only set when it's false
  const sidebarDisabled = isSidebarDisabled(page);
  const schemaContent = buildSchemaContent(page.meta.schema);
  const brandConfig = localisedConfig.get(brand);
  if (!brandConfig) {
    throw new Error(`No localised config found for brand ${brand}`);
  }
  const contentConfig = getLocalisedConfig({
    locale: region ?? "",
    fallbackKey: "default",
    localisedConfig: brandConfig,
  });

  // On the FP site, there is a Match Center widget which renders at the top of the homepage fully expanded
  // on the landing page, so we've added another WidgetMap which renders it here, rather than with the other page widgets
  const widgetsAtTopOfPage =
    page.full_url_path === "homepage" && page.meta.widgets
      ? page.meta.widgets.filter((w) => w.component === "MatchCenter")
      : [];

  const canonicalLinks = getPageCanonicalLinks({
    pageTemplate: page.meta.template,
    fullUrlPath: page.full_url_path,
    region,
  });

  const isNoIndexPage = NO_INDEX_PAGE_REGEX_PATTERNS.some((pattern) =>
    pattern.test(page.full_url_path)
  );

  const langCode = getHtmlLangCode(region ?? "");
  return (
    <>
      <Head>
        <title>{`${page.meta.title ? page.meta.title : page.title} | ${
          BRAND_DETAILS_MAP[brand].fullName
        }`}</title>
        <meta name="title" content={page.meta.title} />
        <meta name="description" content={page.meta.description} />
        {isNoIndexPage ? <meta name="robots" content="noindex" /> : null}

        {canonicalLinks.length ? (
          <>
            {canonicalLinks.map((link) => (
              <link
                key={`${link.href}-${link.rel}-${link.hrefLang ?? "default"}`}
                {...link}
              />
            ))}
          </>
        ) : null}

        {schemaContent ? (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schemaContent }}
          />
        ) : null}

        <meta httpEquiv="content-language" content={langCode} />
        {/* Open Graph */}
        <meta property="og:locale" content={langCode.split("-").join("_")} />
        <meta
          property="og:type"
          content={page.type === "page" ? "website" : "article"}
        />
        <meta property="og:description" content={page.meta.description} />
        <meta
          property="og:title"
          content={`${page.meta.title ? page.meta.title : page.title} | ${
            BRAND_DETAILS_MAP[brand].fullName
          }`}
        />
        <meta
          property="og:url"
          content={
            page.full_url_path === "homepage"
              ? siteUrl
              : `${siteUrl}/${page.full_url_path}`
          }
        />
        {!!page.meta.featured_image ? (
          <meta
            property="og:image"
            content={mediaUrlFromSlug(page.meta.featured_image)}
          />
        ) : null}
      </Head>
      <div className={styles.containerGrid}>
        {page.meta.adverts &&
        advertsData &&
        advertsData.tramlines &&
        advertsData.tramlines.length > 0 ? (
          <Tramline
            isMobileDevice={isMobileDevice}
            position="LEFT"
            tramline={advertsData.tramlines[0]}
          />
        ) : (
          <div></div>
        )}
        <div>
          {brand === FP_BRAND &&
          widgetsAtTopOfPage &&
          widgetsAtTopOfPage.length > 0 ? (
            <div
              className={styles.widgetsAtTopOfPageContainer}
              data-testid="widgetsAtTopOfPageContainer"
            >
              <WidgetMap
                widgets={widgetsAtTopOfPage as ContentWidgetsArray}
                contentLastUpdated={page.updated_at}
              />
            </div>
          ) : null}
          <div
            className={`${styles.contentContainer} ${
              sidebarDisabled && ` ${styles.sidebarDisabled}`
            }`}
          >
            <main data-testid="mainContent">
              <Breadcrumbs
                breadcrumbsToEdit={breadcrumbsToEdit}
                region={region}
              />
              {page.meta.h1title ? (
                <h1 className={styles.pageTitle}>{page.meta.h1title}</h1>
              ) : page.slug !== "homepage" ? (
                <h1 className={styles.pageTitle}>{page.title}</h1>
              ) : null}
              {page.meta.subtitle ? (
                <h2 className={styles.pageSubtitle}>{page.meta.subtitle}</h2>
              ) : null}
              {children}
            </main>
            {!sidebarDisabled ? (
              <aside>
                {page?.meta?.adverts &&
                advertsData?.mpu &&
                advertsData.mpu.length > 0 ? (
                  <Mpu
                    isMobileDevice={isMobileDevice}
                    mpu={advertsData.mpu[0]}
                  />
                ) : null}
                {page?.meta?.sidebarOffers &&
                page.meta?.sidebarOffers?.length > 0 ? (
                  <OfferSidebar
                    offersByCategory={page.meta.sidebarOffers}
                    region={region}
                  />
                ) : null}
                {contentConfig.showSocials && <StayConnected />}
              </aside>
            ) : null}
          </div>
        </div>
        {page.meta.adverts &&
        advertsData &&
        advertsData.tramlines &&
        advertsData.tramlines.length > 0 ? (
          <Tramline
            isMobileDevice={isMobileDevice}
            position="RIGHT"
            tramline={advertsData.tramlines[0]}
          />
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default ContentLayout;
