export const localisedConfig = {
  "ca-on": {
    header: "Featured",
    button: "View All",
  },
  fp: {
    header: "Bonus bets & offers",
    button: "View All Offers",
  },
  br: {
    header: "Apostas grátis e ofertas",
    button: "Ver todas as ofertas",
  },
  default: {
    header: "Free bets & offers",
    button: "View All Offers",
  },
};
