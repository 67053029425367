import { ReactElement } from "react";
import { nanoid } from "nanoid";
import { ContentPane, ContentPanes } from "@/types/api/content";
import { ArrowRight } from "@/components/icons/ArrowRight";
import { ArrowLeft } from "@/components/icons/ArrowLeft";
import {
  CarouselButtonContainerLeft,
  CarouselButtonContainerRight,
  NextCarouselButton,
  Pane,
  PaneImage,
  PrevCarouselButton,
  SlideContainer,
  StyledCarousel,
} from "@/components/Carousel/Carousel.style";
import { ConditionalWrapper } from "../ConditionalWrapper/ConditionalWrapper";

const getSlideCount = (panes: ContentPane[], isMobile: boolean) => {
  if (panes.length < 2 || isMobile) {
    return 1;
  }
  return 3;
};

interface ButtonProps {
  onClick?: () => void;
  isMobileDevice: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const PrevButton = ({ onClick = () => {}, isMobileDevice }: ButtonProps) => (
  <CarouselButtonContainerLeft>
    <PrevCarouselButton isMobileDevice={isMobileDevice} onClick={onClick}>
      <ArrowLeft />
    </PrevCarouselButton>
  </CarouselButtonContainerLeft>
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NextButton = ({ onClick = () => {}, isMobileDevice }: ButtonProps) => (
  <CarouselButtonContainerRight>
    <NextCarouselButton isMobileDevice={isMobileDevice} onClick={onClick}>
      <ArrowRight />
    </NextCarouselButton>
  </CarouselButtonContainerRight>
);

interface CarouselProps {
  panes: ContentPanes | undefined;
  isMobileDevice: boolean;
}

export const Carousel = ({
  panes,
  isMobileDevice,
}: CarouselProps): ReactElement | null => {
  const panesArray = panes ? Object.values(panes) : [];

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: isMobileDevice ? "15%" : "0",
    slidesToShow: getSlideCount(panesArray, isMobileDevice),
    adaptiveHeight: true,
    swipe: true,
    swipeToSlide: true,
    speed: 300,
    touchThreshold: 10,
    infinite: true,
    nextArrow: <NextButton isMobileDevice={isMobileDevice} />,
    prevArrow: <PrevButton isMobileDevice={isMobileDevice} />,
  };

  return panes ? (
    <SlideContainer isMobileDevice={isMobileDevice}>
      <StyledCarousel
        {...settings}
        removeMargin={panesArray.length < 2}
        isMobileDevice={isMobileDevice}
      >
        {panes &&
          panesArray.map(({ image, link, text }) => {
            const wrapper = (children: ReactElement) => (
              <Pane
                // Empty link redirects to locale homepage in url without adding slash
                href={link}
                key={nanoid()}
                target={link.startsWith("http") ? "_blank" : ""}
                data-testid="carouselLink"
              >
                {children}
              </Pane>
            );

            return (
              image && (
                <ConditionalWrapper
                  condition={link !== ""}
                  wrapper={wrapper}
                  key={nanoid()}
                >
                  <PaneImage
                    src={`https://cdn.checkd.media/images/${image}-lg.jpg`}
                    height={isMobileDevice ? "292" : "576"}
                    width={isMobileDevice ? "512" : "1024"}
                    alt={text}
                    priority
                  />
                </ConditionalWrapper>
              )
            );
          })}
      </StyledCarousel>
    </SlideContainer>
  ) : null;
};
