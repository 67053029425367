import { ReactElement, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface ReactPortalProps {
  children: ReactElement;
  elementSelector?: string;
}

export const ReactPortal = ({ children }: ReactPortalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>("#portal");
    setMounted(true);
  }, []);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};
